.main-title {
	@apply h-auto bg-transparent flex flex-col lg:items-center lg:flex-row lg:justify-between mx-2 lg:mx-0
}

.main-title__info {
	@apply flex flex-col items-center lg:flex-row lg:justify-start lg:items-center mb-3 lg:mb-0
}

.main-title__title {
	@apply font-medium text-2xl lg:text-4xl text-white mr-3
}

.main-title__company-name {
	@apply font-medium text-lg lg:text-2xl mr-3 text-gray-400 mb-2 lg:mb-0
}

.main-title__range-picker {
	@apply flex justify-center
}