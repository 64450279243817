.attacks-graph {
	@apply lg:w-1/2
}

.attacks-graph__title {
	@apply font-medium text-2xl text-gray-900 mb-8
}

.attacks-graph__content {
	@apply flex justify-center content-center
}