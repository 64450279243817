.foreground-container {
	@apply w-full
}

.foreground-main-title {
	@apply mb-5
}

.foreground-requests-graphs {
	@apply bg-white rounded-t-lg pt-12 pb-12 px-10
}

.foreground-basic-table {
	@apply w-full bg-white pt-12 pb-12 px-10
}

.foreground-attacks-threats-graph {
	@apply w-full bg-white rounded-b-lg pt-12 pb-12 px-10 flex flex-col lg:flex-row content-between content-center
}
