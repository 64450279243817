.status-icon {
	@apply h-4 w-24 lg:h-4 lg:w-32 bg-green-100 rounded-2xl
}

.status-icon__info {
	@apply h-full flex items-center justify-evenly
}

.status-icon__content {
	font-size: 14px;
	@apply text-green-800 font-medium sm:text-base
}

.status-icon__content--offline {
	@apply text-red-800
}

.status-icon--offline {
	@apply bg-red-100
}