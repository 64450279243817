.requests-graphs {
	@apply w-full flex flex-col lg:content-between
}

.requests-graphs__title {
	@apply font-medium text-2xl text-gray-900 mb-8
}

.requests-graphs__content {
	@apply flex flex-col lg:flex-row lg:justify-between items-center
}