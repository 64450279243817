.footer {
	@apply flex flex-col content-center lg:flex-row lg:justify-between px-20 mt-5 mb-5
}

.footer__logo {
	@apply flex justify-center items-center whitespace-nowrap mb-3 lg:mb-0
}

.footer__logo img {
	@apply h-5 w-20 ml-2
}

.footer__checked {
	@apply flex justify-center items-center whitespace-nowrap
}

.footer__checked img {
	@apply h-4 w-4 mr-1
}