.line-chart-label {
	@apply flex justify-start p-5
}

.icon {
	background-color: rgba(27, 58, 209, 0.8);
	@apply flex-row justify-center items-center p-5 mr-5 rounded-md
}

.line-chart-label__info {
	@apply flex-col justify-between
}

.line-chart-label__title {
	@apply font-medium text-base text-gray-500
}

.line-chart-label__value {
	@apply font-semibold text-3xl text-gray-900
}