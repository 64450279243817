@import "antd/dist/antd.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-picker.ant-picker-range {
  border-radius: 6px;
}

.ant-table-tbody {
	font-weight: 500;
}

.ant-alert.ant-alert-error {
	border-radius: 6px;
	margin-bottom: 5px;
}

@media(max-width: 768px) {
	.ant-picker-panels {
		flex-direction: column;
	}
}

body, html {
	font-family: "Inter", sans-serif;
}